<template>
  <section
    class="fixed top-0 left-0 w-screen h-screen z-[100] grid overflow-y-auto overflow-x-hidden md:overflow-hidden backdrop-blur"
    :class="{
      'place-content-center': orientation === 'landscape'
    }"
    @click.self="doClose"
  >
    <div
      class="bg-base w-screen md:rounded-xl relative h-mobile-full overflow-auto flex flex-col"
      :class="{
        'md:w-[50vw] md:!h-[80vh]': !props.large,
        'md:w-[70vw] md:!h-[90vh]': props.large
      }"
      v-if="!props.blank"
    >
      <header class="pt-4 px-4 sticky top-0 z-20 flex items-start pb-2 bg-zinc-900 border-b border-zinc-800">
        <!-- header -->
        <h2 class="grow-1 w-full text-white">
          <slot name="title" />
        </h2>

        <!-- toolbar -->
        <div class="flex-none flex gap-2 justify-end">
          <slot name="toolbar" />
          <button class="flex-none p-1 rounded-full bg-black/70 hover:bg-white hover:text-black text-white" @click="doClose">
            <XMarkIcon class="h-6 w-6" />
          </button>
        </div>
      </header>

      <div class="pane-content px-4 w-full h-full overflow-x-hidden">
        <slot />
      </div>

      <footer class="mt-auto w-full pb-4">
        <slot name="footer" />
      </footer>
    </div>

    <!-- for blank pane -->
    <slot v-else />
  </section>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline'
const { orientation, matchSize } = useRwd()
const { isIos } = useDevice()
const { messageConfirm } = useSwal()
const { t } = useNuxtApp().$i18n

const props = defineProps({
  blank: {
    type: Boolean,
    default: false
  },
  confirm: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  }
})

const doClose = async () => {
  if (props.confirm) {
    const { value } = await messageConfirm(t('pane.leaveTitle'), t('pane.leaveInfo'))
    if (value) {
      emit('close')
    }
  } else {
    emit('close')
  }
}

const emit = defineEmits(['close'])
</script>

<style scoped>
.h-mobile-full {
  height: 100vh;
  height: 100dvh;
  max-height: -webkit-fill-available;
}
</style>
